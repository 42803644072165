import React, { FC, ChangeEvent } from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { Grid, Container } from "@mui/material";
import Footer from "src/components/Footer";
import Common_Row from "src/common/Common_Row";
import {
  Card,CardHeader,Box,Button,Divider,TableContainer,Table,TableHead,TableRow,
  TableCell,Checkbox,TablePagination,TableBody,Typography,
} from "@mui/material";
import Authenticate_Dialog from "src/common/Authenticate_Dialog";
import BulkActions from "src/content/applications/Transactions/BulkActions";

interface GetAllSitesProps {
  selectedBulkActions: boolean;
  open: boolean;
  data;
  coloumnKey;
  filteredData;
  deleteId:number;
  setDeleteId:React.Dispatch<React.SetStateAction<string[]>>;
  getAllData: () => Promise<void>;
  updateData,
  deleteData,
  limit: number;
  page: number;
  createData;
  openDialogue,
  setopenDialogue,
  selectedAllData: boolean;
  selectedSomeData: boolean;
  handleSelectAllData: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSelectOneData: (event: ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  headkey,
  selectedData: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  setAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  auth: React.MutableRefObject<boolean>;
  handlePageChange: (event: any, newPage: number) => void;
  handleLimitChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GetAll_Sites: FC<GetAllSitesProps> = ({
  selectedBulkActions,open,handleSelectOneData,deleteId,updateData,loading,selectedData,setAnswer,headkey,coloumnKey,
  deleteData,limit, setOpen,page,data,filteredData,createData,selectedAllData,selectedSomeData,handleSelectAllData,
  handlePageChange, handleLimitChange,openDialogue,setopenDialogue
}) => {
  const deleteapi = process.env.REACT_APP_DELETE_SITE;
  return (
    <>
      <Helmet>
        <title>Site Management</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <Authenticate_Dialog
                title="You are not authenticated. Please login again"
                open={open}
                setOpen={setOpen}
              />
              <Card>
                {selectedBulkActions && (
                  <Box flex={1} p={2}>
                    <BulkActions />
                  </Box>
                )}
                {!selectedBulkActions && (
                  <CardHeader
                    action={
                      <Box width={150}>
                        <Button sx={{ margin: 1 }} variant="contained" onClick={createData}>
                          Add site
                        </Button>
                      </Box>
                    }
                    title="All Site List"
                  />
                )}
                <Divider />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedAllData}
                            indeterminate={selectedSomeData}
                            onChange={handleSelectAllData}
                          />
                        </TableCell>
                        {headkey?.map((item, index) => {
                          return (
                            <TableCell key={index} align="center">
                              {item}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    {loading ? (
                      <TableBody>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="left">
                          <Typography variant="h4">Loading...</Typography>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                      </TableBody>
                    ) : (
                      <Common_Row
                        selectedData={selectedData}
                        setAnswer={setAnswer}
                        deleteData={deleteData}
                        updateData={updateData}
                        deleteId={deleteId}
                        coloumnKey={coloumnKey}
                        paginatedData={data}
                        setopenDialogue={setopenDialogue}
                        openDialogue={openDialogue}
                        deleteapi={deleteapi}
                        handleSelectOneData={handleSelectOneData}
                      />
                    )}
                  </Table>
                </TableContainer>
                <Box p={2}>
                  <TablePagination
                    component="div"
                    count={filteredData?.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                  />
                </Box>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
export default GetAll_Sites;

