import { Typography, Avatar, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function PageHeader() {
  const loggedin_Name=localStorage.getItem('login_data');
  const parsedData=JSON.parse(loggedin_Name)
  const user = {
    name: parsedData?.data?.name,
    avatar: '/static/images/avatars/1.jpg'
  };
  const theme = useTheme();

  return (
    <Grid container alignItems="center">
      {/* <Grid item>
        <Avatar
          sx={{
            mr: 2,
            width: theme.spacing(8),
            height: theme.spacing(8)
          }}
          variant="rounded"
          alt={user.name}
          src={user.avatar}
        />
      </Grid> */}
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Welcome, {user.name}!
        </Typography>
        {/* <Typography variant="subtitle2">
          Today is a good day to start trading crypto assets!
        </Typography> */}
      </Grid>
    </Grid>
  );
}

export default PageHeader;
