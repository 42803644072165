import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from 'src/common/apiCommonData';

interface FormData{
  name: string;
  url: string;
  description : string,
  // image_logo : string,
  // web_logo: string,
  logo_url: string,
}
interface SiteData extends FormData {
  _id: number;
}

const Create_Site: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const token = localStorage.getItem('token');
  const data = location.state as SiteData;
  console.log("Data id is", data?._id);
  const update_Site_Url = process.env.REACT_APP_UPDATE_SITE;
  const add_Site_Url = process.env.REACT_APP_ADD_SITE;

  const [field, setField] = useState<SiteData>({
    name: data?.name || "",
    url: data?.url || "",
    _id: data?._id,
    description : data?.description || "",
    // image_logo: data?.image_logo  || "",
    logo_url : data?.logo_url || ""
  });
  const [selectedFile,setSelectedFile]=useState<File>();
  const [base64String,setBase64String]=useState<String>();

  // Validation states
  const [error, setError] = useState({
    nameError: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setField((prevField) => ({
      ...prevField,
      [name]: value,
    }));

    // Validate name length (minimum 3 characters)
    setError((prevError) => ({
      ...prevError,
      nameError: name === 'name' && value.length < 3,
    }));
  };

  function previewFile(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files[0];
    const name = e.target.name
    const value= e.target.value
    setField({...field,[e.target.name]:e.target.value})
    
    if (file) {
      const reader = new FileReader();
  
      reader.onload = function (e) {
        const result = e.target.result;
        if (typeof result === 'string') {
          const base64 = result.split(',')[1];
  
          setField(() => ({
            ...field,
            [name]:value,
            web_logo: base64, // Store the Base64-encoded image in the web_logo property
          }));
  
          setSelectedFile(file);
          setBase64String(base64);
        }
      };
  
      reader.readAsDataURL(file);
    }
  }
  
  // const token=localStorage.getItem('token')
  const updateData = () => {
    const formData = new FormData();
    {data ?
    axios
      .put(`${update_Site_Url}${data?._id}`, field, {
        headers: {
          'accept': '*/*',
          'Auth-Token': `${token}`,
        },
      })
      .then((res) => {
        console.log("Successfully Updated", res.data);
        setStatus(res.data?.status);
      })
      .catch((error) => {
        console.log("Error occurred in updating the user data", error);
      }) :
    formData.append('name', field.name);
    formData.append('url', field.url);
    formData.append('logo_url', field.logo_url);
    formData.append('description', field.description);
    axios
      .post(`${add_Site_Url}`, formData, {
        headers: {
          'accept': '*/*',
          'Auth-Token': `${token}`,
        },
      })
      .then((res) => {
        console.log('Successfully registered', res.data);
        setStatus(res.data?.status);
      })
      .catch((error) => {
        console.log('Error occurred while adding users', error);
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!error.nameError) {
      updateData();
    }
    // Perform submission or further logic
  };

  useEffect(() => {
    if (status) {
      navigate("/management/getAllSites");
    }
  }, [status, navigate]);

  console.log("!@#$%^&",field)

  return (
    <form onSubmit={handleSubmit}>
      <Box
        component="form"
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          width: '90%',
          marginTop: '100px',
          marginLeft: 'auto',
          marginRight: 'auto',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
          padding: '1rem',
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <h1>{data ? "Update Site" :"Add Site"}</h1>
        </div>
        <Box sx={{ display: 'flex', gap: '2rem', width: '100%' }}>
          <TextField
            id="standard-multiline-flexible"
            placeholder="Enter your Site name"
            label="Site Name"
            multiline
            name="name"
            value={field.name}
            maxRows={4}
            variant="outlined"
            sx={{ flex: 1 }}
            onChange={handleChange}
            error={error.nameError}
            helperText={error.nameError ? 'Name must be at least 3 characters long' : ''}
          />
          <TextField
            id="standard-textarea"
            label="Site Url"
            placeholder="Enter your Site Url"
            multiline
            name="url"
            value={field.url}
            variant="outlined"
            sx={{ flex: 1 }}
            onChange={handleChange}
          />
          </Box>
          <Box sx={{ display: 'flex', gap: '2rem', width: '100%' }}>
           <TextField
            id="standard-textarea"
            label="Site Description"
            placeholder="Enter your Site Description"
            multiline
            name="description"
            value={field?.description}
            variant="outlined"
            sx={{ flex: 1 }}
            onChange={handleChange}
          />

<TextField
            id="standard-textarea"
            label="Logo url"
            placeholder="Enter your website logo url"
            multiline
            name="logo_url"
            value={field?.logo_url}
            variant="outlined"
            sx={{ flex: 1 }}
            onChange={handleChange}
          />
          
           {/* <input type='file'
            id="standard-textarea"
            // label="Web Logo"
            placeholder="Upload your website url"
            // multiline
            name="image_logo"
            value={field?.image_logo}
            // variant="outlined"
            // sx={{ flex: 1 }}
            onChange={previewFile}
          /> */}
        </Box>
        <Button variant='contained' sx={{ margin: '30px' }} onClick={handleSubmit} disabled={error.nameError}>
        {data ? "Update Site" :"Add Site"}
        </Button>
      </Box>
    </form>
  );
};

export default Create_Site;
