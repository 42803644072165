import GetAll_Sites from './GetAll_Sites';
import Common_Fun from 'src/HOC/Common_Fun';
import { siteHeadKey as headkey,siteColoumnKey as coloumnKey } from 'src/common/apiCommonData';

const getApiData=process.env.REACT_APP_GETALL_SITE;
const addDataRoute='/management/createSite'
const updateDataRoute='/management/createSite'

const Enhanced_GetSites = Common_Fun(GetAll_Sites, getApiData,addDataRoute,updateDataRoute,headkey, coloumnKey);

export default Enhanced_GetSites;
