export const token = localStorage.getItem('token');

export const config = {
    headers: {
      'accept': '*/*',
      'Auth-Token': `${token}`,
    },
  };

 export const deleteApi=process.env.REACT_APP_DELETE_USER;
 export const get_ALL_User_APi=process.env.REACT_APP_GETALL_USER;

 export const userHeadKey=[
  'USER NAME','USER ID','EMAIL','STATUS','ACTIONS'
 ]
 export const siteColoumnKey=[
  '_id','name','url'
 ]
 export const siteHeadKey=[
  'SITE ID','SITE NAME','SITE URL	','ACTIONS'
 ]

 export const userColoumnKey=[
  'name','_id','email','is_admin'
 ]

 export const currencies = [
  {
    value: 0,
    label: 'simple_user',
  },
  {
    value: 1,
    label: "is_admin",
  },
  
];