import {
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  Tooltip,
  useTheme,
  IconButton,
  TableBody,
} from "@mui/material";
import ResponsiveDialog from "./ResponsiveDialog";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";

const Common_Row = ({
  paginatedData,
  selectedData,
  setAnswer,
  deleteId,
  coloumnKey,
  handleSelectOneData,
  setopenDialogue,
  openDialogue,
  updateData,
  deleteData,
  deleteapi
}) => {
  const theme = useTheme();
  // const delete_Site_Api = process.env.REACT_APP_DELETE_SITE;
  return (
    <>
      <TableBody>
        {paginatedData?.map((user, index) => {
          const isUserSelected = selectedData?.includes(user._id);
          return (
            <TableRow hover key={index} selected={isUserSelected}>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isUserSelected}
                  onChange={(event) => handleSelectOneData(event, user._id)}
                  value={isUserSelected}
                />
              </TableCell>
              {coloumnKey.map((item, index) => {
                return (
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {user[item]}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell align="center">
                <Tooltip
                  title="Edit Site"
                  arrow
                  onClick={() => updateData(user)}
                >
                  <IconButton
                    sx={{
                      "&:hover": {
                        background: theme.colors.primary.lighter,
                      },
                      color: theme.palette.primary.main,
                    }}
                    color="inherit"
                    size="small"
                  >
                    <EditTwoToneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Delete Site"
                  arrow
                  onClick={() => deleteData(user._id)}
                >
                  <IconButton
                    sx={{
                      "&:hover": { background: theme.colors.error.lighter },
                      color: theme.palette.error.main,
                    }}
                    color="inherit"
                    size="small"
                  >
                    <DeleteTwoToneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <ResponsiveDialog
                  openDialogue={openDialogue}
                  setopenDialogue={setopenDialogue}
                  title="site"
                  setAnswer={setAnswer}
                  id={deleteId}
                  apiUrl={deleteapi}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

export default Common_Row;
