import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { currencies } from "src/common/apiCommonData";
import MenuItem from '@mui/material/MenuItem';
import { config } from "src/common/apiCommonData";
import axios from "axios";
import { fi } from "date-fns/locale";

interface UserData {
  _id?: number;
  name: string;
  email: string;
  password?: string;
  password_confirmation?: string;
  is_admin?:number
}

interface optionData{
  value : string;
  label : string
}

const Create_User: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const data = location.state as UserData;

  const [field, setField] = useState<UserData>({
    name: data?.name || "",
    email: data?.email || "",
    _id: data?._id,
    is_admin: data?.is_admin || 0
  });

  // Validation states
  const [error, setError] = useState({
    nameError: false,
    emailError: false,
    passwordError: false,
    passwordConfirmationError: false,
  });

   const token = localStorage.getItem('token');


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setField((prevField) => ({
      ...prevField,
      [name]: value,
    }));

    // Validate name length (minimum 3 characters)
    setError((prevError) => ({
      ...prevError,
      nameError: name === "name" && value.length < 3,
    }));

    // Validate email using regex
    setError((prevError) => ({
      ...prevError,
      emailError:
        name === "email" &&
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value),
    }));

    if (name === "password") {
      setError((prevError) => ({
        ...prevError,
        passwordError:
          value.length > 0 && (value.length < 6 || value.length > 20),
      }));
    }

    if (name === "password_confirmation") {
      setError((prevError) => ({
        ...prevError,
        passwordConfirmationError: value !== field.password,
      }));
    }
  };

  const addUser_Api = process.env.REACT_APP_ADD_USER;
  const updateUser_Api = process.env.REACT_APP_UPDATE_USER;

  const postData = () => {
    // Create a FormData object to hold the form data
    const formData = new FormData();
    formData.append("name", field.name);
    formData.append("email", field.email);
    if (data?._id ==  undefined) {
      formData.append("password", field.password);
      formData.append("password_confirmation", field.password_confirmation);
      formData.append("is_admin",`${field.is_admin}`);
    }

    const api = data?._id ? `${updateUser_Api}${data._id}` : addUser_Api;

    // Perform the POST/PUT request using axios (or any other HTTP client library of your choice)
    axios
      .request({
        url: api,
        method: data?._id ? "put" : "post",
        data: data?._id? field:formData,
        headers: {
          'accept': '*/*',
          'Auth-Token': `${token}`,
        }
      })
      .then((response) => {
        console.log("Data posted successfully!", response.data);
        setStatus(true); // Set status to true to trigger the navigation to '/management/profile/getAllUsers'
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        // Handle errors as per your requirements (e.g., display an error message to the user)
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if there are any errors before posting the data
    if (
      error.nameError ||
      error.emailError ||
      error.passwordError ||
      error.passwordConfirmationError
    ) {
      console.log("Please fill in all fields correctly");
    } else {
      postData();
      // Perform submission or further logic
    }
  };

  useEffect(() => {
    if (status) {
      navigate("/management/getAllUsers");
    }
  }, [status, navigate]);

  console.log("FIELDDDDDD",field)

  return (
      <Box
        component="form"
        sx={{
          backgroundColor: "white",
          display: "flex",
          width: "90%",
          marginTop: "100px",
          marginLeft: "auto",
          marginRight: "auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <h1>{data ? "Update User" : "Add User"}</h1>
        </div>
        <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
          <TextField
            id="standard-multiline-flexible"
            placeholder="Enter your name"
            label="Name"
            multiline
            name="name"
            value={field.name}
            maxRows={4}
            variant="outlined"
            sx={{ flex: 1 }}
            onChange={handleChange}
            error={error.nameError}
            helperText={
              error.nameError ? "Name must be at least 3 characters long" : ""
            }
          />
          <TextField
            id="standard-textarea"
            label="Email"
            placeholder="Enter your email"
            multiline
            name="email"
            value={field.email}
            variant="outlined"
            sx={{ flex: 1 }}
            onChange={handleChange}
            error={error.emailError}
            helperText={error.emailError ? "Invalid email format" : ""}
          />
        </Box>
        {data?._id ? (
          ""
        ) : (
          <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Password"
              placeholder="Enter your password"
              multiline
              name="password"
              value={field.password}
              maxRows={4}
              variant="outlined"
              sx={{ flex: 1 }}
              onChange={handleChange}
              error={error.passwordError}
              helperText={
                error.passwordError
                  ? "Password length should be between 6 and 20 characters"
                  : ""
              }
            />
            <TextField
              id="standard-textarea"
              label="Confirm password"
              placeholder="Re-enter your password"
              multiline
              name="password_confirmation"
              value={field.password_confirmation}
              variant="outlined"
              sx={{ flex: 1 }}
              onChange={handleChange}
              error={error.passwordConfirmationError}
              helperText={
                error.passwordConfirmationError
                  ? "Password confirmation does not match"
                  : ""
              }
            />
             <TextField
          id="outlined-select-currency"
          name="is_admin"
          select
          label="Select"
          defaultValue="0"
          onChange={handleChange}
          value={field.is_admin}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
          </Box>
        )}
        <Button onClick={handleSubmit}
          variant="contained"
          sx={{ margin: "30px" }}
          type="submit"
          disabled={
            error.nameError ||
            error.emailError ||
            error.passwordError ||
            error.passwordConfirmationError
          }
        >
          {data ? "Update User" : "Add User"}
        </Button>
      </Box>
  );
};

export default Create_User;
