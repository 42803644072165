import GetAll_Users from './GetAll_Users';
import Common_Fun from 'src/HOC/Common_Fun';
import { userHeadKey as headkey ,userColoumnKey as coloumnKey} from 'src/common/apiCommonData';

const getApiData=process.env.REACT_APP_GETALL_USER;
const addDataRoute='/management/createUser'
const updateDataRoute='/management/createUser'

const Enhanced_User = Common_Fun(GetAll_Users, getApiData,addDataRoute,updateDataRoute,headkey,coloumnKey);

export default Enhanced_User;
